<template>
	<v-card class="d-flex flex-column my-card">
		<v-row no-gutters dense class="d-flex align-start flex-grow-0 pb-6">
			<v-col cols="12">
				<p class="heading-sf20">Company Information</p>
			</v-col>
		</v-row>
		<v-form v-model="valid" ref="form" lazy-validation>
			<v-row no-gutters dense>
				<v-col cols="12">
					<v-row no-gutters dense>
						<v-col cols="12" class="pb-0">
							<label>Company Profile</label>
						</v-col>

						<v-col cols="12" class="pt-1">
							<div class="position-relative image" @click="selectProfileImage">
								<v-img
									v-if="company_information.company_profile_link"
									:src="company_information.company_profile_link"
									class="rounded-circle"
									width="80"
									height="80"
								></v-img>
								<v-avatar v-else-if="companyName" color="#2974ff" size="80" class="avtar-text">{{
									companyName
										.split(' ')
										.map((x) => x[0].toUpperCase())
										.join('')
								}}</v-avatar>

								<div class="overlay rounded-circle"></div>
								<div class="position-absolute" style="top: 0; cursor: pointer">
									<div class="edit-pic"><v-icon class="mr-1">mdi-pencil</v-icon></div>
								</div>
								<v-file-input
									class="comapny-profile-image"
									id="companyProfile"
									@change="updateCompanyProfile('company_profile', $event)"
									single-line
									outlined
									dense
									:readonly="$store.state.setting.distributor.role === 'AU'"
									height="48"
									color="#CACEDA"
									type="file"
									accept="image/*"
									:value="companyProfile"
									placeholder="Company Profile"
								></v-file-input>
							</div>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12">
					<v-row no-gutters dense>
						<v-col cols="12" class="pb-0">
							<label>Company Name</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-text-field
								@keyup="updateValue('name', $event)"
								single-line
								outlined
								dense
								:readonly="$store.state.setting.distributor.role === 'AU'"
								height="48"
								color="#CACEDA"
								type="text"
								:value="companyName"
								:rules="nameRules"
								placeholder="Company name"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12">
					<v-row no-gutters dense>
						<v-col cols="12" class="pb-0">
							<label>Address</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-autocomplete
								@keyup="findAddress($event)"
								@blur="selectAddress($event)"
								single-line
								outlined
								dense
								:readonly="$store.state.setting.distributor.role === 'AU'"
								height="48"
								color="#CACEDA"
								type="text"
								append-icon
								:item-value="address"
								item-color="#F7F9FC"
								cache-items
								:items="googleAddress"
								return-object
								v-model="address"
								:rules="addressRules"
								placeholder="Address"
								required
							></v-autocomplete>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="6" class="pr-sm-2">
					<v-row no-gutters dense>
						<v-col cols="12" class="pb-0">
							<label>Main Contact</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-text-field
								@keyup="updateValue('main_contact', $event)"
								single-line
								outlined
								dense
								:readonly="$store.state.setting.distributor.role === 'AU'"
								height="48"
								color="#CACEDA"
								type="text"
								:value="mainContact"
								:rules="mainContactRules"
								placeholder="Main contact"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="6" class="pl-sm-2">
					<v-row no-gutters dense>
						<v-col cols="12" class="pb-0">
							<label>Title</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-text-field
								@keyup="updateValue('title', $event)"
								single-line
								outlined
								dense
								:readonly="$store.state.setting.distributor.role === 'AU'"
								height="48"
								color="#CACEDA"
								type="text"
								:value="companyTitle"
								:rules="titleRules"
								placeholder="Title"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="6" class="pr-sm-2">
					<v-row no-gutters dense class="p-0">
						<v-col cols="12" class="pb-0">
							<label>Phone</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-text-field
								@keyup="updateValue('phone', $event)"
								single-line
								outlined
								dense
								:readonly="$store.state.setting.distributor.role === 'AU'"
								height="48"
								color="#CACEDA"
								type="text"
								:value="companyPhone"
								:rules="phoneRules"
								placeholder="Phone"
								v-mask="mask"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" sm="6" class="pl-sm-2">
					<v-row no-gutters dense class="p-0">
						<v-col cols="12" class="pb-0">
							<label>Email</label>
						</v-col>
						<v-col cols="12" class="pt-1">
							<v-text-field
								@keyup="updateValue('email', $event)"
								single-line
								outlined
								dense
								:readonly="$store.state.setting.distributor.role === 'AU'"
								height="48"
								color="#CACEDA"
								type="text"
								:value="companyEmail"
								:rules="emailRules"
								placeholder="Email address"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-form>
		<v-row no-gutters dense align="stretch" class="d-flex">
			<v-col v-if="error_message != ''" class="d-flex align-center mt-auto p-0 custom-error" cols="12">{{ this.error_message }}</v-col>

			<v-col cols="12" class="d-flex justify-end mt-auto">
				<v-btn
					class="my-auto-btn my_elevation"
					id="my_elevation"
					@click="companyInformation()"
					:disabled="!valid || $store.state.setting.distributor.role === 'AU'"
					depressed
					>Save changes</v-btn
				>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { googlePlaces, companyDetailUpdate, getCompanyDetail } from '../../services/settingservice'
import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'
export default {
	data() {
		return {
			role: '',
			active_tab: false,
			mask: '(###)-###-####',
			show: true,
			valid: true,
			error_message: '',
			nameRules: [
				(v) => !!v || 'Company name is required',
				// v =>
				// 	/^[A-Za-z0-9_ ]+$/.test(v) ||
				// 	"Input alphabet character only"
			],
			addressRules: [(v) => !!v || 'address is required'],
			mainContactRules: [(v) => !!v || 'Main contact is required', (v) => /^[A-Za-z_ ]+$/.test(v) || 'Input alphabet character only'],
			titleRules: [(v) => !!v || 'Title is required', (v) => /^[A-Za-z_ ]+$/.test(v) || 'Input alphabet character only'],
			phoneRules: [(v) => !!v || 'Phone number is required', (v) => (v && v.length >= 14 && v.length <= 14) || 'Phone number must be 10 digit'],
			emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid'],
			company_information: {
				name: '',
				address: '',
				main_contact: '',
				title: '',
				phone: '',
				email: '',
				place_id: '',
				session_token: '',
				company_profile: {},
				company_profile_link: '',
			},
			address: '',
			address_data: [],
			google_address: [],
		}
	},

	async mounted() {
		const uuidv4 = require('uuid/v4')
		this.company_information.session_token = uuidv4()
		await getCompanyDetail()
		this.company_information.company_profile_link = this.$store.state.setting.company.company_profile_link
		this.$store.state.setting.company.session_token = this.company_information.session_token
		this.address = this.$store.state.setting.company.address
		this.google_address.push({
			address: this.$store.state.setting.company.address,
			place_id: this.$store.state.setting.company.place_id,
		})
		this.address_data.push(this.$store.state.setting.company.address)
	},

	created() {
		this.$store.watch(
			(state) => {
				return state.auth.error
			},
			() => {
				if (this.active_tab === true) {
					this.error_message = this.$store.state.auth.error
				}
			},
			{ deep: true }
		)

		this.$store.watch(
			(state) => {
				return state.setting.company
			},
			() => {
				this.address = this.$store.state.setting.company.address
				this.google_address.push({
					address: this.$store.state.setting.company.address,
					place_id: this.$store.state.setting.company.place_id,
				})
				this.address_data.push(this.$store.state.setting.company.address)
			},
			{ deep: true }
		)
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true)
		},
		googleAddress() {
			return this.address_data
		},
		companyName() {
			return this.$store.state.setting.company.name
		},
		mainContact() {
			return this.$store.state.setting.company.main_contact
		},
		companyTitle() {
			return this.$store.state.setting.company.title
		},
		companyPhone() {
			return this.$store.state.setting.company.phone
		},
		companyEmail() {
			return this.$store.state.setting.company.email
		},
		companyProfile() {
			return this.$store.state.setting.company.company_profile
		},
	},

	methods: {
		selectProfileImage() {
			document.getElementById('companyProfile').click()
		},
		updateValue(fieldname, event) {
			let ob = {
				fieldname: fieldname,
				value: event.target.value,
			}
			this.$store.commit('updateCompanyValue', ob)
		},

		updateCompanyProfile(fieldname, event) {
			this.company_information.company_profile_link = URL.createObjectURL(event)
			let ob = {
				fieldname: fieldname,
				value: event,
			}
			this.$store.commit('updateCompanyValue', ob)
		},

		async findAddress(e) {
			let len = e.target.value.length / 3
			if (Number.isInteger(len) && len != 0) {
				let data = {
					address: e.target.value,
					session_token: this.company_information.session_token,
				}
				let address_data = await googlePlaces(data)

				address_data.dataset.forEach((item) => {
					let ob = {
						address: item.description,
						place_id: item.place_id,
					}
					this.address_data.push(item.description)
					this.google_address.push(ob)
				})
			}
		},

		selectAddress(e) {
			let add = e.target.value
			this.company_information.address = add
			this.google_address.forEach((item) => {
				if (item.address === add) {
					this.company_information.place_id = item.place_id
					let ob = {
						fieldname: 'address',
						value: add,
					}
					this.$store.commit('updateCompanyValue', ob)
					let ob1 = {
						fieldname: 'place_id',
						value: item.place_id,
					}
					this.$store.commit('updateCompanyValue', ob1)
				}
			})
		},

		async companyInformation() {
			if (this.$refs.form.validate()) {
				this.$store.commit('errorMessage', '')
				this.error_message = ''
				this.active_tab = true

				await this.companyInfo()
				const formData = new FormData()
				formData.append('name', this.$store.state.setting.company.name)
				formData.append('address', this.$store.state.setting.company.address)
				formData.append('main_contact', this.$store.state.setting.company.main_contact)
				formData.append('title', this.$store.state.setting.company.title)
				formData.append('phone', this.$store.state.setting.company.phone)
				formData.append('email', this.$store.state.setting.company.email)
				formData.append('company_profile', this.$store.state.setting.company.company_profile)
				formData.append('session_token', this.$store.state.setting.company.session_token)
				formData.append('place_id', this.$store.state.setting.company.place_id)
				let data = await companyDetailUpdate(formData)

				if (!data.data) {
					this.error_message = this.$store.state.auth.error
				} else {
					this.$store.commit('companyInfo', data.data)
					this.companyInfo(data.data)
					this.active_tab = false
					let docRef = await firebase
						.firestore()
						.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
						.doc(`${this.$store.state.auth.user_data.company_id}DC`)
					docRef.update({
						fullname: this.$store.state.setting.company.name,
						address: this.$store.state.setting.company.address,
						phone: this.$store.state.setting.company.phone.replace(/\D+/g, ''),
						email: this.$store.state.setting.company.email,
						profile_link: this.$store.state.setting.company.company_profile_link,
					})
				}
			}
		},

		companyInfo() {
			let data = this.$store.state.setting.company
			this.error_message = ''
			this.company_information.name = data.name
			this.company_information.address = data.address
			this.company_information.main_contact = data.main_contact
			this.company_information.title = data.title
			this.company_information.phone = data.phone
			this.company_information.email = data.email
			this.company_information.place_id = data.place_id
			this.company_information.company_profile_link = data.company_profile_link
			this.company_information.company_profile = data.company_profile
			this.address = data.address
			this.address_data.push(data.address)
			this.google_address.push({
				address: data.address,
				place_id: data.place_id,
			})
		},
	},
}
</script>

<style lang="scss">
.comapny-profile-image.v-text-field.v-input--dense .v-input__append-inner {
	margin: auto !important;
}

.file-input,
.comapny-profile-image {
	display: none;
}
.edit-input {
	top: 30px;
}

.edit-pic {
	display: none;
	position: absolute;
	top: 27px;
	left: 27px;
	z-index: 99;
	.v-icon {
		color: #fff;
	}
}
.image {
	width: fit-content;
	&:hover {
		.edit-pic,
		.overlay {
			display: block;
		}
	}
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.overlay {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(31, 29, 29, 0.253);
}

.avtar-text {
	color: #fff;
	font-weight: bold;
	font-size: 25px;
}
</style>
